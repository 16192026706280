<template>
    <div class="Adviser">
      <!-- <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="回复状态:" prop="">
                <el-radio-group v-model="queryInfo.type" @change="search">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="1">已回复 </el-radio-button>
                  <el-radio-button label="2">未回复 </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div> -->
  
      <div class="tab_txt">
        <div class="input-with">
          <el-input
            placeholder="请输入企业名称，用户名称"
            v-model="queryInfo.condition"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()">搜索</el-button>
          </el-input>
          <!-- <el-button >批量导出</el-button> -->
        </div>
        <el-table
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          @sort-change="changeSort"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          row-key="id"
        >
          <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
          <el-table-column label="编号" align="center" type="index">
            <template slot-scope="scope">
              <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="微信头像" align="center">
            <template slot-scope="scope">
              <img :src="scope.row.headImgUrl" width="42px" height="42px" alt="" />
            </template>
          </el-table-column>
  
          <el-table-column label="姓名" prop="realName" align="center"></el-table-column>
          <el-table-column label="手机号" prop="phone" align="center">
            <template v-slot="{ row }">
              <span>{{ row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.companyFullName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最新咨询问题" prop="" width="400px" align="center">
            <template v-slot="{ row }">
              <span>{{ row.content }}</span>
            </template>
          </el-table-column>
          <el-table-column  sortable prop="maxCreateTime" label="最新咨询时间" width="200px" align="center">
            <template v-slot="{ row }">
              <span>{{ row.maxCreateTime }}</span>
            </template>
          </el-table-column>
            <el-table-column  sortable  label="最早咨询时间" width="200px" prop="minCreateTime" align="center">
            <template v-slot="{ row }">
              <span>{{ row.minCreateTime }}</span>
            </template>
          </el-table-column>
  
          <el-table-column
            label="消息回复"
            align="center"
            class-name="small-padding fixed-width"
            width="200px"
            v-if="$hasPerms('consultant:info')"
          >
            <template slot-scope="scope">
              <div class="news_reply">
                
                <div  class="red_reply" @click="infoClick(scope.row)">
                  <span>详情</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { communicationsGinseng } from '@/api/servicesmage.js'
  const defaultQueryInfo = Object.freeze({
    condition: null,
    orders: 2,//1->最新咨询时间升序;2->最新咨询时间降序;3->最早咨询时间升序;4->最早咨询时间降序
    pageNum: 1,
    pageSize: 10,
    partnerChannelId: '1'//合作伙伴编号
  })
  export default {
    name: 'Adviser',
    data() {
      return {
        queryInfo: { ...defaultQueryInfo },
        listData: [],
        // 遮罩层
        loading: false,
        parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),

        // 总条数
        total: 0
      }
    },
    created() {
      this.search()
    },
    methods: {
      async search() {
        this.queryInfo.partnerChannelId= this.parteninfo.id
        this.loading = true
        const res = await communicationsGinseng(this.queryInfo)
        if (res.data.resultCode == 200) {
          this.listData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      },
      infoClick(item) {
        this.$router.push({
          name: 'onlinedetail',
          query: { id: item.id }
        })
      },
      //排序
      changeSort(val) {
        console.log(val);
        //val里面返回prop和order
        if (val.prop == 'maxCreateTime') {
          if (val.order == 'ascending') {
            //updateAt列升序时
            this.queryInfo.orders = '1'
            this.search()
          } else if (val.order == 'descending') {
            //updateAt列降序时
            this.queryInfo.orders = '2'
            this.search()
          } else {
            //updateAt列不排序时
            this.search()
          }
        }else   if (val.prop == 'minCreateTime') {
          if (val.order == 'ascending') {
            //updateAt列升序时
            this.queryInfo.orders = '3'
            this.search()
          } else if (val.order == 'descending') {
            //updateAt列降序时
            this.queryInfo.orders = '4'
            this.search()
          } else {
            //updateAt列不排序时
            this.search()
          }
        }
      },
      // 分页
      handleSizeChange(val) {
        this.queryInfo.pageSize = val
        this.search()
        // console.log(`每页 ${val} 条`)
      },
      // 分页
      handleCurrentChange(val) {
        this.queryInfo.pageNum = val
        this.search()
        // console.log(`当前页: ${val}`)
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .Adviser {
    .span_user {
      display: inline-block;
      width: 112px;
      height: 33px;
      line-height: 33px;
      text-align: center;
    }
    .form_info {
      background: #ffffff;
      padding: 20px;
    }
  
    .tab_txt {
      background: #ffffff;
      margin-top: 20px;
      padding: 20px;
    }
  
    .span_div {
      font-size: 14px;
      color: #7c7f8e;
      margin-right: 12px;
    }
  
    .input-with {
      width: 100%;
      overflow: hidden;
        display: flex;
        justify-content: left;
      .input-with-select {
        width: 400px;
        float: right;
      }
  
      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  
    .in_table {
      width: 100%;
      margin-top: 20px;
  
      .img_url {
        width: 42px;
        height: 42px;
        border-radius: 4px;
      }
    }
  
    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }
    .news_reply {
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      .replied {
        padding: 3px 4px;
        background: #e4e4e4;
        border-radius: 2px;
      }
      .red_reply {
        color: #4E93FB;
        display: flex;
        align-items: center;
      }
      .red_circular {
        width: 18px;
        height: 18px;
        border-radius: 10px;
        display: inline-block;
        background: #ff4940;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
      }
    }
  }
  </style>
  